<template>
  <div>
    <jaya-connection-layout :text="$t('signInUp.welcomeMessage')">
      <validation-observer v-slot="{invalid, handleSubmit}">
        <b-form @submit.prevent="handleSubmit(showPopUpCgu)">
          <b-row>

            <!-- Email -->
            <b-col cols="12">
              <b-form-group label="Email">
                <validation-provider v-slot="{errors}" rules="required|email">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>

                    <b-form-input v-model="email"
                        autofocus
                        placeholder="Email"
                        type="email"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="registerErrors.email === 'allreadyUsedEmail'"
                      class="text-danger">
                    {{ $t("signInUp.alreadyUsedEmail") }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('signInUp.language')">
                <validation-provider v-slot="{errors}" immediate rules="required">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="FlagIcon"/>
                    </b-input-group-prepend>
                    <b-form-select
                        v-model="language"
                        :options="languageOptions"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12">
              <b-form-group :label="$t('signInUp.passwordAndConfirm')">
                <p class="font-small-2 font-italic">
                  {{
                    $t("signInUp.passwordOrder")
                  }}
                </p>

                <validation-provider v-slot="{errors}" rules="required" vid="password">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password"
                        :type="passwordFieldType"
                        :placeholder="$t('signInUp.password')"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>

                <validation-provider v-slot="{errors}" rules="required|confirmed:password">
                  <b-input-group class="mt-50">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password_confirm"
                        :type="passwordFieldType"
                        placeholder="Confirmation"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="registerErrors.password === 'tooShort'" class="text-danger">
                    {{ $t('signInUp.passwordErrors.tooShort') }}
                  </p>
                  <p v-if="registerErrors.password === 'tooCommonPassword'" class="text-danger">
                    {{ $t('signInUp.passwordErrors.tooCommonPassword') }}
                  </p>
                  <p v-if="registerErrors.password === 'numeric'" class="text-danger">
                    {{ $t('signInUp.passwordErrors.numeric') }}
                  </p>
                  <p v-if="registerErrors.password === 'tooSimilar'" class="text-danger">
                    {{ $t('signInUp.passwordErrors.tooSimilar') }}
                  </p>
                  <p v-if="registerErrors.password === 'badPassword'" class="text-danger">
                    {{ $t('signInUp.passwordErrors.badPassword') }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Name -->
            <b-col cols="12">
              <b-form-group :label="$t('signInUp.form.fullNameLabel')">
                <validation-provider v-slot="{errors}" name="Prénom" rules="maxlength:30">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="first_name"
                                  :placeholder="$t('signInUp.form.firstNamePlaceholder')"
                                  type="text"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="registerErrors.first_name === 'badFirstName'"
                     class="text-danger">
                    {{ $t('signInUp.form.firstNameTooLong') }}
                  </p>
                </validation-provider>
                <validation-provider v-slot="{errors}" name="Nom" rules="maxlength:150">
                  <b-input-group class="mt-50">
                    <b-form-input v-model="last_name"
                                  :placeholder="$t('signInUp.form.lastNamePlaceholder')"
                                  type="text"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="registerErrors.last_name === 'badLastName'"
                     class="text-danger">
                    {{ $t('signInUp.form.lastNameTooLong') }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>


            <!-- Category -->
            <b-col cols="12">
              <b-form-group :label="$t('signInUp.form.categoryLabel')">
                <validation-provider v-slot="{errors}"  immediate rules="required">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GridIcon"/>
                    </b-input-group-prepend>
                    <b-form-select
                        v-model="category"
                        :options="optionCategory"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Buttons-->
            <b-col class="d-flex justify-content-around flex-wrap mb-1" cols="12">
              <b-button class="m-25 flex-grow-1" to="/login" variant="outline-primary">
                {{ $t('signInUp.form.alreadyHaveAccount') }}
              </b-button>
              <b-button
                  :disabled="invalid"
                  class="m-25 flex-grow-1"
                  type="submit"
                  variant="primary">
                {{ $t('signInUp.form.validateRegistration') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </jaya-connection-layout>

    <b-modal
        v-model="popUpMoreInfo"
        :cancel-title="$t('signInUp.cancel')"
        modal-class="modal-primary"
        ok-only
        :ok-title="$t('signInUp.ok')"
        :title="$t('signInUp.title')">
      <p> {{$t('signInUp.emailSent', {email: display_email})}} </p>
      <p> {{$t('signInUp.checkSpam')}} </p>
      <p> {{$t('signInUp.notReceived')}} <span class="cursor-pointer font-medium text-primary" @click="resendEmail"> {{$t('signInUp.clickHere')}} </span> {{$t('signInUp.forResend')}} </p>
    </b-modal>

    <b-modal
        v-model="popUpCgu"
        modal-class="modal-primary"
        ok-only
        scrollable
        hide-header-close
        :ok-title="$t('signInUp.approveTOS')"
        :title="$t('signInUp.agreementNeeded')"
        @ok="onCguAccepted">
        <GdprDocViewer doc_type="general-conditions" />
    </b-modal>

    <b-modal
        v-model="popUpCharter"
        modal-class="modal-primary"
        ok-only
        scrollable
        :ok-title="$t('signInUp.approveCharter')"
        :title="$t('signInUp.agreementNeeded')"
        @ok="onCharterAccepted"
    >
      <GdprDocViewer doc_type="gdpr-charter" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
} from "bootstrap-vue"
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {confirmed, email, required} from 'vee-validate/dist/rules'
import GdprDocViewer from "@/views/gdpr/GdprDocViewer";

let i18n = null
function getI18nMessage(key, params) {
  return i18n.t(`validation.${key}`, params);
}

extend('required', {
  ...required,
  message: (fieldName, placeholders) => getI18nMessage('required', { ...placeholders, _field_: fieldName })
});

extend('email', {
  ...email,
  message: (fieldName) => getI18nMessage('email', { _field_: fieldName })
});

extend('confirmed', {
  ...confirmed,
  message: (fieldName) => getI18nMessage('confirmed', { _field_: fieldName })
});

extend('maxlength', {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ['length'],
  message: (fieldName, placeholders) => getI18nMessage('maxlength', { ...placeholders, _field_: fieldName })
});

extend('minlength', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: (fieldName, placeholders) => getI18nMessage('minlength', { ...placeholders, _field_: fieldName })
});

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    GdprDocViewer,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      email: "",
      password: "",
      password_confirm: "",
      first_name: "",
      last_name: "",
      category: null,
      registerErrors: {},
      popUpMoreInfo: false,
      popUpCgu: false,
      popUpCharter: false,
      cguAccepted: false,
      charterAccepted: false,
      optionCategory: null,
      display_email: "",
      language: null,
      languageOptions: [
        {value: null, text: this.$t("signInUp.selectLanguage"), disabled: true},
        {
          value: "fr",
          text: "Français"
        },
        {
          value: "en", text: "English"
        },
        {value: "es", text: "Español"}
        ]
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    getCategories() {
      return this.optionCategory.filter(e => e.language===this.$i18n.locale)
    }
  },
  beforeCreate() {
    i18n = this.$i18n
  },
  mounted() {
    this.optionCategory = [{ value: null, text: this.$t("signInUp.whatCategory"), disabled: true, language: this.$i18n.locale}]
    this.$store.dispatch("cfp/fetchQuizType", { year: new Date().getFullYear() })
        .then(()=>{
            this.$store.state.cfp.quizType.forEach((e)=>{
              if (e.language === this.$i18n.locale) {
                this.optionCategory.push({
                  value: e.type,
                  text: this.$store.state.cfp.quizTypeName.find(el => el.type === e.type).name[e.language],
                  disabled: false,
                  language: e.language
                })
              }
            })
        })
        .catch(()=>{
          this.$notify({
            time: 4000,
            title: this.$t('Error'),
            text: this.$t('quizTypeCouldNotBeFetched'),
            color: "danger"
          })
    })
  },
  methods: {
    showPopUpCgu() {
      this.popUpCgu=true;
      this.popUpCharter=false;
    },
    onCguAccepted() {
      this.cguAccepted = true;
      this.popUpCharter = true;
      this.popUpCgu = false;
    },
    onCharterAccepted() {
      this.charterAccepted = true;
      this.popUpCharter = false;

      if (this.cguAccepted && this.charterAccepted) {
        this.register()
      }
    },
    register() {
      this.$loading(true)
      // If form is not validated or user is already login return
      const payload = {
        username: this.email,
        email: this.email,
        password: this.password,
        password_confirm: this.password_confirm,
        last_name: this.last_name,
        first_name: this.first_name,
        category: this.category,
        language: this.language,
      }
      payload.redirect_uri = process.env.VUE_APP_REDIRECT_URI_VERIFY

      this.$store.dispatch("account/register", payload)
          .then(() => {
            this.$notify({
              text: "La signature des documents a bien été prise en compte",
              color: "primary",
              duration: 3000,
            });

            this.popUpMoreInfo = true
            this.display_email = this.email
            this.email = ""
            this.first_name = ""
            this.last_name = ""
            this.password = ""
            this.password_confirm = ""
            this.registerErrors = {}
            this.category = null
            this.$loading(false)
          })
          .catch((error) => {
            this.$notify(
                {
                  title: this.$t('Error'),
                  text: "L'inscription a échoué",
                  time: 3000,
                  color: "danger"
                }
            )

            let errors = {}
            if (error.response && error.response.status === 400) {
              if (error.response.data.email) {
                errors.email = "allreadyUsedEmail"
              }
              if (error.response.data.password) {
                errors.password = this.$parsePasswordErrors(error.response.data.password)
              }
              if (error.response.data.first_name) {
                errors.first_name = "badFirstName"
              }
              if (error.response.data.last_name) {
                errors.last_name = "badLastName"
              }
            }
            this.registerErrors = errors

            this.$loading(false)
          })
    },
    resendEmail() {
      let payload = {email: this.display_email}
      this.$store.dispatch("account/resend_validation_email", payload)
          .then(() => {
            this.$notify(
                {
                  title: "Renvoi effectué",
                  text: "Un email vous a été renvoyé à l'adresse : " + this.display_email,
                  time: 4000,
                  color: "primary"
                }
            )
          })
          .catch(() => {
            this.$notify(
              {
                title: this.$t('Error'),
                text: "L'envoi du mail de validation a échoué",
                time: 4000,
                color: "danger"
              }
          )
      })
    },
    gdprDocTypeToName(doc_type) {
      return this.$store.state.gdpr.gdprDocTypes.find((type) => type.choice === doc_type).name
    },
  },
  watch: {
    language(val) {
      this.$i18n.locale=val
      this.optionCategory = [{ value: null, text: this.$t("signInUp.whatCategory"), disabled: true, language: this.$i18n.locale}]
      this.$store.state.cfp.quizType.forEach((e)=>{
        if (e.language === this.$i18n.locale) {
          this.optionCategory.push({
            value: e.type,
            text: this.$store.state.cfp.quizTypeName.find(el => el.type === e.type).name[e.language],
            disabled: false,
            language: e.language
          })
        }
      })
      if (this.$store.state.cfp.quizType.filter(e => e.language === this.$i18n.locale).length === 1){
        this.category = this.$store.state.cfp.quizType.filter(e => e.language === this.$i18n.locale)[0].type
      } else {
        this.category = null
      }
    }
  }
};
</script>
